<template>
  <div class="post-container" v-if="post">
    <div class="post">
      <h1>{{ post.title }}</h1>
      <div v-html="renderedMarkdown" class="post-content"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Post } from "@/models/Post";
import axios from "axios";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/github.css"; // 코드 블록 스타일
import { Toc } from "@/models/Toc";
import { SERVER_URL } from "@/main";

export default defineComponent({
  name: "PostView",
  setup() {
    const route = useRoute();
    const postId = route.params.id;
    const post = ref<Post | null>(null);
    const tocItems = ref<Toc[]>([]);

    const setMetaTags = (post: any) => {
      const head = document.head;

      // 기존 메타 태그 삭제
      Array.from(
        head.querySelectorAll(
          "meta[name='description'], meta[name='keywords'], meta[property^='og:']"
        )
      ).forEach((meta) => {
        head.removeChild(meta);
      });

      // 새 메타 태그 추가
      const metaTags = [
        {
          name: "description",
          content: post.content.slice(0, 50) || "게시글 요약 내용",
        },
        { property: "og:title", content: post.title },
        {
          property: "og:description",
          content: post.content.slice(0, 50) || "게시글 요약 내용",
        },
        {
          property: "og:url",
          content: `https://blog.seungheon.net/posts/${post.id}`,
        },
        { property: "og:type", content: "article" },
      ];

      metaTags.forEach(({ name, property, content }) => {
        const meta = document.createElement("meta");
        if (name) meta.name = name;
        if (property) meta.setAttribute("property", property);
        meta.content = content;
        head.appendChild(meta);
      });
    };

    const fetchPost = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/posts/${postId}`);
        post.value = response.data;
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };
    marked.setOptions({
      pedantic: false,
      gfm: true,
      breaks: true,
    });
    const renderer = new marked.Renderer();
    renderer.code = ({ text, lang }: { text: string; lang?: string }) => {
      const language = lang && hljs.getLanguage(lang) ? lang : "plaintext";
      try {
        const highlighted = hljs.highlight(text, { language }).value;
        return `<pre><code class="hljs language-${language}">${highlighted}</code></pre>`;
      } catch (error) {
        console.error("Highlight.js error:", error);
        return `<pre><code>${text}</code></pre>`;
      }
    };
    // 헤딩을 추출하여 목차 생성
    renderer.heading = ({ tokens, depth }: { tokens: any; depth: number }) => {
      const text = tokens[0].raw;
      const id = text.toLowerCase().replace(/\s+/g, "-");
      const toc = new Toc(id, text, depth);
      tocItems.value.push(toc);
      return `<h${depth} id="${id}">${text}</h${depth}>`;
    };

    const renderedMarkdown = computed(() => {
      return post.value ? marked.parse(post.value.content, { renderer }) : "";
    });

    onMounted(() => {
      fetchPost().then(() => {
        setMetaTags(post.value);
      });
    });

    return {
      post,
      renderedMarkdown,
      tocItems,
    };
  },
});
</script>

<style>
main {
  background-color: var(--bg-color);
}
.post-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px; /* 최대 너비 설정 */
  margin: 0 auto; /* 중앙 정렬 */
  padding: 40px 20px;
  position: relative; /* 상대 위치 설정 */
}

/* 포스트 영역 */
.post {
  flex: 1;
  max-width: 1000px;
  padding: 30px;
  background-color: var(--bg-color);
  border-radius: 10px;
}

/* 포스트 내용 스타일 */
.post-content {
  color: var(--text-color);
  text-align: left;
  margin: 0 10px;
  max-width: 100%;
}

/* 포스트 제목 스타일 */
.post h1 {
  font-size: 2rem;
  color: var(--text-color); /* 다크모드와 라이트모드에 맞는 색상 적용 */
  margin-bottom: 20px;
}

/* 코드 블록 스타일 */
pre {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
}
.post-content blockquote {
  border-left: 4px solid #444;
  display: block;
  margin: 2rem 0px;
  padding: 1rem 1rem 1rem 2rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.post-content code {
  background-color: var(--bg-inline-code);
  padding: 0.2em 0.4em;
  font-size: 85%;
  border-radius: 3px;
}
.post-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.post-content th,
.post-content td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.post-content th {
  font-weight: bold;
}

.post-content img {
  max-width: 100%; /* 부모 요소의 너비에 맞게 조정 */
  height: auto; /* 가로 비율에 맞게 높이 자동 조정 */
  display: block; /* 블록 요소로 표시 */
  margin: 10px auto; /* 상하 여백 10px, 좌우 자동 정렬로 가운데 배치 */
  max-height: 650px; /* 이미지의 최대 높이를 400px로 제한 (원하는 값으로 변경) */
}
@media (max-width: 1024px) {
  .post {
    width: 100%;
    margin-right: 0;
  }
}
</style>
