import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "not-found" }



export default /*@__PURE__*/_defineComponent({
  ...{
  name: "NotFound",
},
  __name: 'NotFound',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "404 - 페이지를 찾을 수 없습니다", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, "요청하신 페이지가 존재하지 않습니다.", -1)),
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("홈으로 돌아가기")
      ])),
      _: 1
    })
  ]))
}
}

})