import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PostView from "../views/PostView.vue";
import PostsView from "@/views/PostsView.vue";
import CreatePostView from "@/views/CreatePostView.vue";
import NotFound from "../views/NotFound.vue";
import GuestbookView from "@/views/GuestbookView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/posts",
    name: "Posts",
    component: PostsView,
  },
  {
    path: "/post/:id",
    name: "Post",
    component: PostView,
  },
  {
    path: "/create-post",
    name: "CreatePost",
    component: CreatePostView,
  },
  // {
  //   path: "/guestbook",
  //   name: "Guestbook",
  //   component: GuestbookView,
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  // { path: "/drafts", name: "Drafts", component: DraftsView }, // 임시글 페이지 경로
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
