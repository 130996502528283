<template>
  <header class="header">
    <nav class="nav">
      <ul class="nav-menu">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/posts">Posts</router-link></li>
        <li><a href="https://github.com/seungheon123">Github</a></li>
        <!-- <li><router-link to="/guestbook">Guests</router-link></li> -->
      </ul>
      <div class="buttons">
        <button @click="toggleDarkMode" class="dark-mode-button">
          <span class="material-icons">
            {{ isDarkMode ? "light_mode" : "dark_mode" }}
          </span>
        </button>
        <!-- <router-link to="/drafts" class="drafts-button">임시 글</router-link> -->
        <router-link v-if="isLoggedIn" to="/create-post" class="write-button"
          >글 작성</router-link
        >
        <button v-if="isLoggedIn" @click="logout" class="logout-button">
          로그아웃
        </button>
      </div>
    </nav>
    <div v-if="showLoginModal" class="login-modal">
      <div class="modal-content">
        <h2>로그인</h2>
        <input type="text" v-model="username" placeholder="Username" />
        <input type="password" v-model="password" placeholder="Password" />
        <button @click="login">로그인</button>
        <button @click="closeLoginModal">취소</button>
        <p v-if="loginError" class="error-message">{{ loginError }}</p>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { SERVER_URL } from "@/main";
import axios from "axios";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";

export default defineComponent({
  name: "HeaderComponent",
  props: {
    isDarkMode: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["toggle-dark-mode"],
  setup(props, { emit }) {
    const showLoginModal = ref(false); // 로그인 모달 상태
    const isLoggedIn = ref(false);
    const username = ref("");
    const password = ref("");
    const loginError = ref("");
    const toggleDarkMode = () => {
      emit("toggle-dark-mode");
    };

    const handleKeydown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

      if (isMac) {
        if (event.ctrlKey && event.altKey && event.key === "n") {
          showLoginModal.value = true; // 로그인 모달 표시
        }
      } else {
        if (event.ctrlKey && event.altKey && event.key === "n") {
          showLoginModal.value = true; // 로그인 모달 표시
        }
      }
    };
    const login = async () => {
      try {
        const response = await axios.post(`${SERVER_URL}/auth/login`, {
          username: username.value,
          password: password.value,
        });
        const token = response.data.accessToken;
        localStorage.setItem("token", token);
        isLoggedIn.value = true;
        showLoginModal.value = false;
        username.value = "";
        password.value = "";
        loginError.value = "";
      } catch (error) {
        console.error("로그인 실패:", error);
        loginError.value =
          "로그인에 실패했습니다. 사용자명과 비밀번호를 확인하세요.";
      }
    };

    const logout = () => {
      localStorage.removeItem("token");
      isLoggedIn.value = false;
    };

    const checkLoginStatus = () => {
      const token = localStorage.getItem("token");
      if (token) {
        isLoggedIn.value = true;
      }
    };
    const closeLoginModal = () => {
      showLoginModal.value = false;
      loginError.value = "";
    };

    onMounted(() => {
      window.addEventListener("keydown", handleKeydown);
      checkLoginStatus();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleKeydown);
    });

    return {
      toggleDarkMode,
      showLoginModal,
      isLoggedIn,
      username,
      password,
      loginError,
      login,
      logout,
      closeLoginModal,
    };
  },
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--border-color);
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1; /* 네비게이션 영역을 확장 */
}

.nav-menu {
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li a {
  text-decoration: none;
  color: var(--text-color); /* 기본 텍스트 색상 변수 사용 */
  font-weight: 500;
  transition: color 0.3s; /* 색상 전환 애니메이션 */
}

/* 호버 시 색상 변경 */
.nav-menu li a:hover {
  color: var(--button-bg-color); /* 버튼 배경색 변수 사용 */
}

/* 버튼 컨테이너를 오른쪽으로 정렬 */
.buttons {
  margin-left: auto; /* 버튼을 오른쪽으로 보냄 */
  display: flex;
  align-items: center;
  gap: 10px;
}

/* 글 작성 버튼 스타일 */
.write-button,
.drafts-button {
  padding: 8px 16px;
  border: 2px solid var(--button-bg-color);
  color: var(--button-bg-color);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.write-button:hover,
.drafts-button:hover {
  background-color: var(--button-bg-color);
  color: #fff;
}

/* 다크 모드 버튼 스타일 */
.dark-mode-button {
  padding: 8px 16px;
  background: transparent;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--text-color); /* 기본 텍스트 색상 */
  transition: transform 0.3s, color 0.3s;
}

/* 다크 모드에서 버튼 색상 변경 */
.dark-mode .dark-mode-button {
  color: #f5f5f5; /* 다크 모드에서 버튼 아이콘 색상 */
}

/* 버튼 호버 효과 */
.dark-mode-button:hover {
  transform: scale(1.2);
}

.login-modal {
  position: fixed;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-content input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-content button {
  padding: 10px;
  background-color: var(--button-bg-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: var(--button-hover-bg-color);
}

.logout-button {
  padding: 8px 16px;
  background-color: var(--button-bg-color);
  color: #fff;
  border: 2px solid var(--button-bg-color);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: var(--button-hover-bg-color);
}
</style>
